import {
    CREATE_MANDATE_REQUEST,
    CREATE_MANDATE_SUCCESS,
    CREATE_MANDATE_FAILED,
    EXECUTE_MANDATE_REQUEST,
    EXECUTE_MANDATE_SUCCESS,
    EXECUTE_MANDATE_FAILED,
    APPLY_COUPON_REQUEST,
    APPLY_COUPON_FAILED,
    APPLY_COUPON_SUCCESS,
    GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST, GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS, GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED,
} from '../Constants/actionTypes.constants';
import {get} from "lodash";

export const initialStore = {
    vpa: '',
    pspRefNo: '',
    paymentPattern: '',
    paymentAmount: 500,
    error: false,
    loading: false,
    codeApplied: false,
    codeAppliedSuccess: false,
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case CREATE_MANDATE_REQUEST:
            return {
                ...state,
                pspRefNo: '',
                error: false,
                loading: true,
            };
        case CREATE_MANDATE_SUCCESS:
            return {
                ...state,
                pspRefNo: action.data.pspRefNo,
                error: false,
                loading: false,
            };
        case CREATE_MANDATE_FAILED:
            return {
                ...state,
                pspRefNo: '',
                error: true,
                loading: false,
            };
        case EXECUTE_MANDATE_REQUEST:
            return {
                ...state,
                error: false,
            };
        case EXECUTE_MANDATE_SUCCESS:
            return {
                ...state,
                error: false,
            };
        case EXECUTE_MANDATE_FAILED:
            return {
                ...state,
                error: true,
            };
        case APPLY_COUPON_REQUEST:
            return {
                ...state,
                codeApplied: true,
                codeAppliedSuccess: false,
                loading: true,
                error: false,
            };
        case APPLY_COUPON_SUCCESS:
            return {
                ...state,
                codeApplied: true,
                codeAppliedSuccess: true,
                paymentAmount: action.data.Amount,
                loading: false,
                error: false,
            };
        case APPLY_COUPON_FAILED:
            return {
                ...state,
                codeApplied: true,
                codeAppliedSuccess: false,
                loading: false,
                error: true,
            };
        case GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS:
            const userData = get(action.data, 'userData', {});
            const mandate = userData.mandates && userData.mandates.mandates &&
                userData.mandates.mandates[userData.mandates.mandates.length-1];
            return {
                ...state,
                vpa: get(mandate, 'payerVirtualAddress', ''),
                paymentPattern: get(mandate, 'pattern', ''),
                paymentAmount: get(mandate, 'amount', ''),
                pspRefNo: get(mandate, 'pspRefNo', ''),
                loading: false,
                error: false,
            };
        case GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state
    }
}
