import {
  GET_QUESTIONS_LIST_REQUEST,
  GET_QUESTIONS_LIST_SUCCESS,
  GET_QUESTIONS_LIST_FAILED,
  SAVE_ANSWERS_REQUEST,
  SAVE_ANSWERS_SUCCESS,
  SAVE_ANSWERS_FAILED,
  GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST,
  GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS,
  GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED,
  SUBMIT_RISK_FORMS_REQUEST,
  SUBMIT_RISK_FORMS_SUCCESS,
  SUBMIT_RISK_FORMS_FAILURE,
  UPDATE_FORM_DATA,
} from '../Constants/actionTypes.constants';
import { get } from 'lodash';
import additionalQuestions from "./additionalQuestions"
export const initialStore = {
  questionsList: [],
  savedAnswers: [],
  error: false,
  riskFormData: {},
};
console.log(additionalQuestions,"additionalQuestions")
export default (state = initialStore, action) => {
  switch (action.type) {
    case GET_QUESTIONS_LIST_REQUEST:
      return {
        ...state,
        questionsList: [],
        error: false,
      };
    case GET_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        questionsList: [...action.data.questions,...additionalQuestions],
        error: false,
      };
    case GET_QUESTIONS_LIST_FAILED:
      return {
        ...state,
        questionsList: [],
        error: true,
      };
    case SAVE_ANSWERS_REQUEST:
      return {
        ...state,
        savedAnswers: [],
        loading: true,
        error: false,
      };
    case SAVE_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        savedAnswers: action.answer_list,
        error: false,
      };
    case SAVE_ANSWERS_FAILED:
      return {
        ...state,
        savedAnswers: [],
        loading: false,
        error: true,
      };
    case GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS:
      const userData = get(action.data, 'userData', {});
      return {
        ...state,
        loading: false,
        error: false,
        questionsList: [...get(userData, 'user_risk_profile', ''),...additionalQuestions],
      };
    case GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_FORM_DATA:
      return {
        ...state,
        riskFormData: Object.assign({}, state.riskFormData, action.data),
      };
    default:
      return state;
  }
};
