import { get } from 'lodash';
import {
  SEND_PHONE_VERIFICATION_REQUEST,
  SEND_PHONE_VERIFICATION_SUCCESS,
  SEND_PHONE_VERIFICATION_FAILED,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  GET_DOCUMENTS_FLAG_REQUEST,
  GET_DOCUMENTS_FLAG_SUCCESS,
  GET_DOCUMENTS_FLAG_FAILED,
  GET_IB_USER_DETAILS_REQUEST,
  GET_IB_USER_DETAILS_SUCCESS,
  GET_IB_USER_DETAILS_FAILED,
  GET_STATE_AND_CITY_REQUEST,
  GET_STATE_AND_CITY_SUCCESS,
  GET_STATE_AND_CITY_FAILED,
  SAVE_IB_DETAILS_FORM_REQUEST,
  SAVE_IB_DETAILS_FORM_SUCCESS,
  SAVE_IB_DETAILS_FORM_FAILED,
  SET_ACTIVE_STEP_NUMBER,
  SET_PAN_IMG_SRC,
  SET_PAN_UPLOAD_STATUS,
  RESET_PAN,
  RESELECT_PAN,
  UPDATE_PAN_DATA,
  SET_CHEQUE_IMG_SRC,
  RESET_CHEQUE,
  RESELECT_CHEQUE,
  SET_BANK_STATEMENT_IMG_SRC,
  SET_CHEQUE_UPLOAD_STATUS,
  ADD_IB_DOCUMENTS_REQUEST,
  ADD_IB_DOCUMENTS_SUCCESS,
  ADD_IB_DOCUMENTS_FAILED,
  GET_IB_DISCLOSURE_FORMS_REQUEST,
  GET_IB_DISCLOSURE_FORMS_SUCCESS,
  GET_IB_DISCLOSURE_FORMS_FAILED,
  SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST,
  SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS,
  SAVE_SIGNED_DISCLOSURE_FORMS_FAILED,
  SAVE_IB_UPDATE_STATUS_REQUEST,
  SAVE_IB_UPDATE_STATUS_SUCCESS,
  SAVE_IB_UPDATE_STATUS_FAILED,
  CREATE_IB_ACCOUNT_REQUEST,
  CREATE_IB_ACCOUNT_SUCCESS,
  CREATE_IB_ACCOUNT_FAILED,
  GET_PAN_OCR_DATA_REQUEST,
  GET_PAN_OCR_DATA_SUCCESS,
  GET_PAN_OCR_DATA_FAILED,
  VERIFY_PAN_DATA_REQUEST,
  VERIFY_PAN_DATA_SUCCESS,
  VERIFY_PAN_DATA_FAILED,
  VERIFY_PAN_DATA_STATUS,
  GET_CHEQUE_OCR_DATA_REQUEST,
  GET_CHEQUE_OCR_DATA_SUCCESS,
  GET_CHEQUE_OCR_DATA_FAILED,
  UPDATE_CHEQUE_DATA,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_FAILED,
  VERIFY_ACCOUNT_SUCCESS,
  PARSE_BANK_STATEMENT_REQUEST,
  PARSE_BANK_STATEMENT_SUCCESS,
  PARSE_BANK_STATEMENT_VALIDATION,
  PARSE_BANK_STATEMENT_FAILED,
  SEND_EMAIL_VERIFICATION_REQUEST,
  SEND_EMAIL_VERIFICATION_SUCCESS,
  SEND_EMAIL_VERIFICATION_FAILED,
  GET_IB_ACCOUNT_STATUS_REQUEST,
  GET_IB_ACCOUNT_STATUS_SUCCESS,
  GET_IB_ACCOUNT_STATUS_FAILED,
  GET_IB_ACCOUNT_STATUS_FLAG_REQUEST,
  GET_IB_ACCOUNT_STATUS_FLAG_SUCCESS,
  GET_IB_ACCOUNT_STATUS_FLAG_FAILED,
  SET_COMPLETED_STEP_NUMBER,
  TOKEN_EXPIRY,
  FETCH_KYC_REQUEST,
  FETCH_KYC_SUCCESS,
  FETCH_KYC_FAILED,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILED,
  GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS,
  GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED,
  GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST,
  GET_TEMPLATE_RATE_REQUEST,
  GET_TEMPLATE_RATE_SUCCESS,
  GET_TEMPLATE_RATE_FAILED,
  UPDATE_TEMPLATE_KEY_REQUEST,
  UPDATE_TEMPLATE_KEY_SUCCESS,
  UPDATE_TEMPLATE_KEY_FAILED
} from '../Constants/actionTypes.constants';
import { getFromLocalStorage } from '../utils';

export const initialStore = {
  loading: false,
  error: false,
  errorMessage: '',
  phoneOtp: '',
  kycPhone: getFromLocalStorage('phoneNumber')
    ? getFromLocalStorage('phoneNumber')
    : '',
  userDetails: {},
  tokenExpiry: false,
  documentsList: {},
  ibUserDetails: {},
  stateCityDetails: {},
  stateCityLoader: false,
  saveIBDetails: {},
  ibUserFullName: '',
  activeStep: 1,
  completedStepNo: 1,
  saveIBDocuments: {},
  disclosureForms: {},
  savedDisclosureForms: {},
  updateIBDetails: '',
  saveUpdateIBstatus: {},
  createIbStatus: {},
  ibFlag: {},
  panSrcUrl: '',
  panUploaded: false,
  userPanData: {},
  panSelected: false,
  panverifyStatus: false,
  panVerifyData: [],
  chequeSrcUrl: '',
  chequeUploaded: false,
  chequeSelected: false,
  bankDetailsSource: '',
  userChequeData: {},
  bankStatementSrcUrl: '',
  kycEmail: '',
  ibAccountStatus: {},
  fetchKycLoader: false,
  fetchKycStatus: '',
  templateRate:0,
  templateExists:false,
  templateKey:'',
  updateTemplate:false,
  templateMsg:'',
  editable:false,
  adviseName:''
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case SEND_PHONE_VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SEND_PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        kycPhone: action.phone,
        phoneOtp: '',
        loading: false,
        error: false,
      };
    case SEND_PHONE_VERIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        phoneOtp: '',
        userDetails: {},
        error: false,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        phoneOtp: action.phoneOtp,
        userDetails: action.data.user_details,
        tokenExpiry: false,
        error: false,
      };
    case VERIFY_OTP_FAILED:
      return {
        ...state,
        phoneOtp: '',
        userDetails: {},
        error: true,
      };
    case GET_DOCUMENTS_FLAG_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_DOCUMENTS_FLAG_SUCCESS:
      return {
        ...state,
        documentsList: action.data,
        error: false,
      };
    case GET_DOCUMENTS_FLAG_FAILED:
      return {
        ...state,
        error: true,
      };
    case GET_IB_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_IB_USER_DETAILS_SUCCESS:
      return {
        ...state,
        ibUserDetails: action.data,
        loading: false,
        error: false,
      };
    case GET_IB_USER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_STATE_AND_CITY_REQUEST:
      return {
        ...state,
        stateCityLoader: true,
        error: false,
      };
    case GET_STATE_AND_CITY_SUCCESS:
      return {
        ...state,
        stateCityLoader: false,
        stateCityDetails: action.data,
        error: false,
      };
    case GET_STATE_AND_CITY_FAILED:
      return {
        ...state,
        stateCityLoader: false,
        error: true,
      };
    case SAVE_IB_DETAILS_FORM_REQUEST:
      return {
        ...state,
        error: false,
      };
    case SAVE_IB_DETAILS_FORM_SUCCESS:
      return {
        ...state,
        saveIBDetails: action.data,
        ibUserFullName: action.fullName,
        error: false,
      };
    case SAVE_IB_DETAILS_FORM_FAILED:
      return {
        ...state,
        error: true,
      };
    case SET_ACTIVE_STEP_NUMBER:
      return {
        ...state,
        activeStep: action.step,
      };
    case SET_COMPLETED_STEP_NUMBER:
      return {
        ...state,
        completedStepNo:action.step,
      };
    case SET_PAN_IMG_SRC:
      return {
        ...state,
        panSrcUrl: action.url,
      };
    case SET_PAN_UPLOAD_STATUS:
      return {
        ...state,
        panUploaded: action.flag,
      };
    case RESET_PAN:
      return {
        ...state,
        panSelected: action.flag,
      };
    case RESELECT_PAN:
      return {
        ...state,
        panUploaded: false,
      };
    case UPDATE_PAN_DATA:
      return {
        ...state,
        userPanData: action.panData,
      };
    case SET_CHEQUE_IMG_SRC:
      return {
        ...state,
        chequeSrcUrl: action.url,
      };
    case SET_CHEQUE_UPLOAD_STATUS:
      return {
        ...state,
        chequeUploaded: action.flag,
      };
    case RESET_CHEQUE:
      return {
        ...state,
        chequeSelected: action.flag,
      };
    case RESELECT_CHEQUE:
      return {
        ...state,
        chequeUploaded: false,
      };
    case UPDATE_CHEQUE_DATA:
      return {
        ...state,
        userChequeData: action.chequeData,
      };
    case SET_BANK_STATEMENT_IMG_SRC:
      return {
        ...state,
        bankStatementSrcUrl: action.url,
      };
    case TOKEN_EXPIRY:
      return {
        ...state,
        tokenExpiry: true,
        error: false,
      };
    case ADD_IB_DOCUMENTS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case ADD_IB_DOCUMENTS_SUCCESS:
      return {
        ...state,
        saveIBDocuments: action.data,
        error: false,
      };
    case ADD_IB_DOCUMENTS_FAILED:
      return {
        ...state,
        error: true,
      };
    case GET_IB_DISCLOSURE_FORMS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_IB_DISCLOSURE_FORMS_SUCCESS:
      return {
        ...state,
        disclosureForms: action.data,
        error: false,
      };
    case GET_IB_DISCLOSURE_FORMS_FAILED:
      return {
        ...state,
        error: true,
      };
    case SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS:
      return {
        ...state,
        savedDisclosureForms: action.data,
        error: false,
      };
    case SAVE_SIGNED_DISCLOSURE_FORMS_FAILED:
      return {
        ...state,
        error: true,
      };
    case SAVE_IB_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case SAVE_IB_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        updateIBDetails: 'success',
        saveUpdateIBstatus: action.data,
        error: false,
      };
    case SAVE_IB_UPDATE_STATUS_FAILED:
      return {
        ...state,
        error: true,
      };
    case CREATE_IB_ACCOUNT_REQUEST:
      return {
        ...state,
        error: false,
      };
    case CREATE_IB_ACCOUNT_SUCCESS:
      return {
        ...state,
        createIbStatus: action.data,
        error: false,
      };
    case CREATE_IB_ACCOUNT_FAILED:
      return {
        ...state,
        error: true,
      };
    case GET_PAN_OCR_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        userPanData: {},
        error: false,
      };
    case GET_PAN_OCR_DATA_SUCCESS:
      return {
        ...state,
        userPanData: action.data,
        panSelected: true,
        loading: false,
        error: false,
      };
    case GET_PAN_OCR_DATA_FAILED:
      return {
        ...state,
        userPanData: {},
        loading: false,
        error: true,
      };
    case VERIFY_PAN_DATA_REQUEST:
      return {
        ...state,
        panverifyStatus: true,
        panVerifyData: [],
        error: false,
      };
    case VERIFY_PAN_DATA_SUCCESS:
      return {
        ...state,
        panverifyStatus: false,
        panVerifyData: action.data,
        error: false,
      };
    case VERIFY_PAN_DATA_STATUS:
      return {
        ...state,
        panverifyStatus: false,
        panVerifyData: action.data,
        error: false,
      };
    case VERIFY_PAN_DATA_FAILED:
      return {
        ...state,
        panverifyStatus: false,
        panVerifyData: [],
        error: true,
      };
    case GET_CHEQUE_OCR_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        bankDetailsSource: '',
        userChequeData: {},
        error: false,
      };
    case GET_CHEQUE_OCR_DATA_SUCCESS:
      return {
        ...state,
        userChequeData: action.data,
        chequeSelected: true,
        bankDetailsSource: 'cheque',
        loading: false,
        error: false,
      };
    case GET_CHEQUE_OCR_DATA_FAILED:
      return {
        ...state,
        bankDetailsSource: '',
        userChequeData: {},
        loading: false,
        error: true,
      };
    case VERIFY_ACCOUNT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case VERIFY_ACCOUNT_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case PARSE_BANK_STATEMENT_REQUEST:
      return {
        ...state,
        userChequeData: [],
        error: false,
        loading: true,
        bankDetailsSource: '',
      };
    case PARSE_BANK_STATEMENT_VALIDATION:
      return {
        ...state,
        userChequeData: action.data,
        loading: false,
        bankDetailsSource: '',
        error: false,
      };
    case PARSE_BANK_STATEMENT_SUCCESS:
      return {
        ...state,
        userChequeData: action.data,
        loading: false,
        bankDetailsSource: 'bank-statement',
        error: false,
      };
    case PARSE_BANK_STATEMENT_FAILED:
      return {
        ...state,
        userChequeData: [],
        loading: false,
        bankDetailsSource: '',
        error: true,
      };
    case SEND_EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        kycEmail: '',
        loading: true,
        error: false,
      };
    case SEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        kycEmail: action.email,
        loading: false,
        error: false,
      };
    case SEND_EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        kycEmail: '',
        loading: false,
        error: true,
      };
    case GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS:
      const userData = get(action.data, 'userData', {});
      return {
        ...state,
        ibFlag: action.data,
        loading: false,
        error: false,
        kycEmail: get(userData, 'email', ''),
        userPanData: get(userData, 'pan', ''),
        userChequeData: get(userData, 'bank_account_details', ''),
         consentPending: get(userData, 'consent_pending', null),
        oauthPending: get(userData, 'oauth_pending', null),
        portfolioAvailable: get(userData, 'portfolio_available', null),
      };
    case GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_IB_ACCOUNT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_IB_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        ibAccountStatus: action.data,
        loading: false,
        error: false,
      };
    case GET_IB_ACCOUNT_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case FETCH_KYC_REQUEST:
      return {
        ...state,
        fetchKycLoader: true,
        fetchKycStatus: '',
        error: false,
      };
    case FETCH_KYC_SUCCESS:
      return {
        ...state,
        fetchKycLoader: false,
        fetchKycStatus: action.data,
        error: false,
      };
    case FETCH_KYC_FAILED:
      return {
        ...state,
        fetchKycLoader: false,
        fetchKycStatus: '',
      };
    case GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetails: {},
        error: false,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {...state.userDetails, ...action.data},
        error: false,
      };
    case GET_USER_DETAILS_FAILED:
      return {
        ...state,
        userDetails: {},
        error: true,
      };
    case GET_TEMPLATE_RATE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
        templateKey: action.data,
      };
    case GET_TEMPLATE_RATE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        templateRate: action.data.fees,
        adviseName:action?.data?.advise,
        templateExists: action.data.success,
        templateMsg: action.data?.message || '',
        editable:action.editable,
      };
    case GET_TEMPLATE_RATE_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
        templateExists: action?.data?.success || false,
        templateMsg: action?.data?.message || 'failed',
      };

    case UPDATE_TEMPLATE_KEY_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UPDATE_TEMPLATE_KEY_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        updateTemplate: action.data?.success,
      };
    case UPDATE_TEMPLATE_KEY_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
        updateTemplate: action.data?.success,
      };
    default:
      return state;
  }
};
