import {
  GET_ADVISORY_CONTRACT_REQUEST,
  GET_ADVISORY_CONTRACT_SUCCESS,
  GET_ADVISORY_CONTRACT_FAILED,
  GET_ESIGN_XML_REQUEST,
  GET_ESIGN_XML_SUCCESS,
  GET_ESIGN_XML_FAILED,
  FETCH_ESIGN_DOCUMENT_REQUEST,
  FETCH_ESIGN_DOCUMENT_SUCCESS,
  FETCH_ESIGN_DOCUMENT_FAILED,
  FETCH_ADVISORY_SIGNED_PDF_REQUEST,
  FETCH_ADVISORY_SIGNED_PDF_SUCCESS,
  FETCH_ADVISORY_SIGNED_PDF_FAILED,
  CHANGE_SELECTION_VALUE,
  UPLOAD_SIGNED_PDF_REQUEST,
  UPLOAD_SIGNED_PDF_SUCCESS,
  UPLOAD_SIGNED_PDF_FAILED,
} from '../Constants/actionTypes.constants';

export const initialStore = {
  advisoryContractPdfUrl: '',
  error: false,
  esignXml: {},
  esignDocument: {},
  advisoryEsignedPdf: {},
  loading: false,
  selectedOption: 'digital',
  manulsignedPdf:'',
  pdfError: false,
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case GET_ADVISORY_CONTRACT_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_ADVISORY_CONTRACT_SUCCESS:
      return {
        ...state,
        advisoryContractPdfUrl: action.data,
        error: false,
      };
    case GET_ADVISORY_CONTRACT_FAILED:
      return {
        ...state,
        error: true,
      };
    case GET_ESIGN_XML_REQUEST:
      return {
        ...state,
        error: false,
        esignXml: {},
      };
    case GET_ESIGN_XML_SUCCESS:
      return {
        ...state,
        esignXml: action.data,
        error: false,
      };
    case GET_ESIGN_XML_FAILED:
      return {
        ...state,
        esignXml: {},
        error: true,
      };
    case FETCH_ESIGN_DOCUMENT_REQUEST:
      return {
        ...state,
        error: false,
        esignDocument: {},
        loading: true,
      };
    case FETCH_ESIGN_DOCUMENT_SUCCESS:
      return {
        ...state,
        esignDocument: action.data,
        error: false,
        loading: false,
      };
    case FETCH_ESIGN_DOCUMENT_FAILED:
      return {
        ...state,
        esignDocument: {},
        error: true,
        loading: false,
      };
    case FETCH_ADVISORY_SIGNED_PDF_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case FETCH_ADVISORY_SIGNED_PDF_SUCCESS:
      return {
        ...state,
        advisoryEsignedPdf: action.data,
        error: false,
        loading: false,
      };
    case FETCH_ADVISORY_SIGNED_PDF_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case CHANGE_SELECTION_VALUE:
      return {
        ...state,
        selectedOption: action.data,
      };
      case UPLOAD_SIGNED_PDF_REQUEST:
        return{
            ...state,
            error: false,
            loading: true,
        }
      case UPLOAD_SIGNED_PDF_SUCCESS:
          return{
              ...state,
              manualsignedPDF: action.data.signedDoc,
              error: false,
        loading: false,
          }
          case UPLOAD_SIGNED_PDF_FAILED:
              return{
                  ...state,
                  pdfError:true,
                  loading:false
              }
    default:
      return state;
  }
};
