export const logout = () => {
    // Clear user token and profile data from window.localStorage
    window.localStorage.removeItem('userDetails');
    window.localStorage.removeItem('token');
  };
  
  export const getProfile = () => {
    // Retrieves the profile data from window.localStorage
    const userProfile = JSON.parse(window.localStorage.getItem('userDetails'));
    return userProfile ? userProfile : {};
  };
  
  export const setProfile = userDetails => {
    // Saves profile data to window.localStorage
    window.localStorage.setItem('userDetails', JSON.stringify(userDetails));
    // Triggers profile_updated event to update the UI
  };
  
  export const setAuthToken = (authToken) => {
    window.localStorage.setItem("token", JSON.stringify(authToken));
  };
  
//   export const setAdminAuthToken = (authToken) => {
//     window.localStorage.setItem("adminToken", JSON.stringify(authToken));
//   };
  
  export const getAuthToken = () => {
    return JSON.parse(window.localStorage.getItem("token"));
  };
  
//   export const getAdminAuthToken = () => {
//     return JSON.parse(window.localStorage.getItem("adminToken"));
//   };
  