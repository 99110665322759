const liabilitiesOptions=[{
    label: "Upto 5,00,000",
    value: "Upto 5,00,000"
  }, {
    label: "5,00,000-10,00,000",
    value: "5,00,000-10,00,000"
  }, {
    label: "10,00,000-15,00,000",
    value: "10,00,000-15,00,000"
  }, {
    label: "15,00,000-25,00,000",
    value: "15,00,000-25,00,000"
  }, {
    label: "25,00,000-35,00,000",
    value: "25,00,000-35,00,000"
  }, {
    label: "35,00,000 and above",
    value: "35,00,000 and above"
  }];


const assetsOptions=[{
label: "Upto 3,99,950",
value: "Upto 3,99,950"
}, {
label: "4,00,000-10,00,000",
value: "4,00,000-10,00,000"
}, {
label: "10,00,050-15,00,000",
value: "10,00,050-15,00,000"
}, {
label: "15,00,050-25,00,000",
value: "15,00,050-25,00,000"
}, {
label: "25,00,050-50,00,000",
value: "25,00,050-50,00,000"
}, {
label: "50,00,050-1,25,00,000",
value: "50,00,050-1,25,00,000"
}, {
label: "1,25,00,050-2,50,00,000",
value: "1,25,00,050-2,50,00,000"
}, {
label: "2,50,00,050-5,00,00,000",
value: "2,50,00,050-5,00,00,000"
}, {
label: "5,00,00,050-25,00,00,000",
value: "5,00,00,050-25,00,00,000"
}, {
label: "25,00,00,050 and above",
value: "25,00,00,050 and above"
}];
const additional=
   [{ question_value: "Please select your assets and liabilities", question_type: "Select", version: 2, values: [
    {options:assetsOptions,header:'Assets',name:'assets'},
    {options:liabilitiesOptions,header:'Liabilities',name:'liabilities'},
],question_id :'assetLiability'
}]

export default additional