import { get, noop } from 'lodash';
import {
    SEND_PHONE_VERIFICATION_REQUEST,
    SEND_PHONE_VERIFICATION_SUCCESS,
    SEND_PHONE_VERIFICATION_FAILED,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    GET_DOCUMENTS_FLAG_REQUEST,
    GET_DOCUMENTS_FLAG_SUCCESS,
    GET_DOCUMENTS_FLAG_FAILED,
    GET_IB_USER_DETAILS_REQUEST,
    GET_IB_USER_DETAILS_SUCCESS,
    GET_IB_USER_DETAILS_FAILED,
    GET_STATE_AND_CITY_REQUEST,
    GET_STATE_AND_CITY_SUCCESS,
    GET_STATE_AND_CITY_FAILED,
    SAVE_IB_DETAILS_FORM_REQUEST,
    SAVE_IB_DETAILS_FORM_SUCCESS,
    SAVE_IB_DETAILS_FORM_FAILED,
    SET_ACTIVE_STEP_NUMBER,
    SET_PAN_IMG_SRC,
    SET_PAN_UPLOAD_STATUS,
    RESET_PAN,
    RESELECT_PAN,
    UPDATE_PAN_DATA,
    SET_CHEQUE_IMG_SRC,
    RESET_CHEQUE,
    RESELECT_CHEQUE,
    UPDATE_CHEQUE_DATA,
    SET_BANK_STATEMENT_IMG_SRC,
    SET_CHEQUE_UPLOAD_STATUS,
    ADD_IB_DOCUMENTS_REQUEST,
    ADD_IB_DOCUMENTS_SUCCESS,
    ADD_IB_DOCUMENTS_FAILED,
    GET_IB_DISCLOSURE_FORMS_REQUEST,
    GET_IB_DISCLOSURE_FORMS_SUCCESS,
    GET_IB_DISCLOSURE_FORMS_FAILED,
    SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST,
    SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS,
    SAVE_SIGNED_DISCLOSURE_FORMS_FAILED,
    SAVE_IB_UPDATE_STATUS_REQUEST,
    SAVE_IB_UPDATE_STATUS_SUCCESS,
    SAVE_IB_UPDATE_STATUS_FAILED,
    CREATE_IB_ACCOUNT_REQUEST,
    CREATE_IB_ACCOUNT_SUCCESS,
    CREATE_IB_ACCOUNT_FAILED,
    GET_PAN_OCR_DATA_REQUEST,
    GET_PAN_OCR_DATA_SUCCESS,
    GET_PAN_OCR_DATA_FAILED,
    VERIFY_PAN_DATA_REQUEST,
    VERIFY_PAN_DATA_SUCCESS,
    VERIFY_PAN_DATA_FAILED,
    VERIFY_PAN_DATA_STATUS,
    GET_CHEQUE_OCR_DATA_REQUEST,
    GET_CHEQUE_OCR_DATA_SUCCESS,
    GET_CHEQUE_OCR_DATA_FAILED,
    VERIFY_ACCOUNT_REQUEST,
    VERIFY_ACCOUNT_FAILED,
    VERIFY_ACCOUNT_SUCCESS,
    PARSE_BANK_STATEMENT_REQUEST,
    PARSE_BANK_STATEMENT_SUCCESS,
    PARSE_BANK_STATEMENT_VALIDATION,
    PARSE_BANK_STATEMENT_FAILED,
    SEND_EMAIL_VERIFICATION_REQUEST,
    SEND_EMAIL_VERIFICATION_SUCCESS,
    SEND_EMAIL_VERIFICATION_FAILED,
    GET_IB_ACCOUNT_STATUS_REQUEST,
    GET_IB_ACCOUNT_STATUS_SUCCESS,
    GET_IB_ACCOUNT_STATUS_FAILED,
    SET_COMPLETED_STEP_NUMBER,
    TOKEN_EXPIRY,
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILED,
    FETCH_KYC_REQUEST,
    FETCH_KYC_SUCCESS,
    FETCH_KYC_FAILED,
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILED,
    GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST, GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS, GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED,
    GET_TEMPLATE_RATE_REQUEST,
    GET_TEMPLATE_RATE_SUCCESS,
    GET_TEMPLATE_RATE_FAILED,
    UPDATE_TEMPLATE_KEY_REQUEST,
    UPDATE_TEMPLATE_KEY_SUCCESS,
    UPDATE_TEMPLATE_KEY_FAILED
} from '../Constants/actionTypes.constants';
import { apiRequest } from "./apiCalls";
import config from '../config';
import { getFromLocalStorage, saveToLocalStorage } from "../utils";
import { setAuthToken, setProfile} from "../utils/authService";

export const sendPhoneVerification = (phone: string, templateKey:any,successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: SEND_PHONE_VERIFICATION_REQUEST, data: {phone:phone,source:'contract',key:templateKey}});
    return apiRequest().post(`${config.baseUrl}api/send_phone_verification`, { phone:phone,source:'contract',key:templateKey })
        .then((res) => {
            dispatch({ type: SEND_PHONE_VERIFICATION_SUCCESS, data: res.data, phone:phone });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: SEND_PHONE_VERIFICATION_FAILED, error });
            failureCallback();
        });
};

export const verifyOtp = (phoneOtp: string, phoneNumber: string, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: VERIFY_OTP_REQUEST, data: {phoneOtp}});
    return apiRequest().post(`${config.baseUrl}api/verify_otps`, { phoneOtp, phoneNumber })
        .then((res) => {
            dispatch({ type: VERIFY_OTP_SUCCESS, data: res.data, phoneOtp: phoneOtp });
            setProfile(res.data.user_details);
            setAuthToken(res.data.token);
            saveToLocalStorage('userId', get(res.data, 'user_details.user_id', ''));
            saveToLocalStorage('phoneNumber', phoneNumber);
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: VERIFY_OTP_FAILED, error });
            failureCallback();
        });
};
export const updateTemplateKey=(templateKey:string)=> (dispatch: any) => {

    dispatch({ type: UPDATE_TEMPLATE_KEY_REQUEST, data: templateKey});
    return apiRequest().post(`${config.baseUrl}api/fee_advise`, {key:templateKey })
        .then((res) => {
            dispatch({ type: UPDATE_TEMPLATE_KEY_SUCCESS,data: res.data});
       
        })
        .catch((error) => {
            dispatch({ type:UPDATE_TEMPLATE_KEY_FAILED, error });
           
        });
}
export const updateAdviseName=(data:any)=> (dispatch: any) => {
   
    dispatch({ type: UPDATE_TEMPLATE_KEY_REQUEST, data: data});
    return apiRequest().post(`${config.baseUrl}api/fee_advise`, {...data })
        .then((res) => {
            dispatch({ type: UPDATE_TEMPLATE_KEY_SUCCESS,data: res.data});
       
        })
        .catch((error) => {
            dispatch({ type:UPDATE_TEMPLATE_KEY_FAILED, error });
           
        });
}
export const getDocumentsFlag = (successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: GET_DOCUMENTS_FLAG_REQUEST});
    return apiRequest().get(`${config.baseUrl}ib/documents_flag`)
        .then((res) => {
            dispatch({ type: GET_DOCUMENTS_FLAG_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: GET_DOCUMENTS_FLAG_FAILED, error });
            failureCallback();
        });
};

export const getIbUserDetails = (successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: GET_IB_USER_DETAILS_REQUEST});
    return apiRequest().get(`${config.baseUrl}user/details`)
        .then((res) => {
            dispatch({ type: GET_IB_USER_DETAILS_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: GET_IB_USER_DETAILS_FAILED, error });
            failureCallback();
        });
};

export const getStateAndCity = (pincode: string) => (dispatch: any) => {
    dispatch({ type: GET_STATE_AND_CITY_REQUEST});
    return apiRequest().post(`${config.baseUrl}cdsl/fetch/city/state`, {user_id:getFromLocalStorage('userId'), pincode: pincode})
        .then((res) => {
            dispatch({ type: GET_STATE_AND_CITY_SUCCESS, data: res.data });
        })
        .catch((error) => {
            dispatch({ type: GET_STATE_AND_CITY_FAILED, error });
        });
};

export const saveIBDetailsForm = (data: any, fullName:string, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: SAVE_IB_DETAILS_FORM_REQUEST});
    return apiRequest().post(`${config.baseUrl}ib/add_details`, data)
        .then((res) => {
            dispatch({ type: SAVE_IB_DETAILS_FORM_SUCCESS, data: res.data, fullName: fullName  });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: SAVE_IB_DETAILS_FORM_FAILED, error });
            failureCallback();
        });
};

export const tokenExpiry = () => (dispatch: any) => {
    dispatch({ type: TOKEN_EXPIRY});
};

export const setActiveStepNumber = (step: number) => (dispatch: any) => {
    dispatch({ type: SET_ACTIVE_STEP_NUMBER, step });
};

export const setCompletedStepNumber = (step: number) => (dispatch: any) => {
    dispatch({ type: SET_COMPLETED_STEP_NUMBER, step });
};

export const setPanImgSrc = (url: string) => (dispatch: any) => {
    dispatch({ type: SET_PAN_IMG_SRC, url });
};

export const setPanUploadStatus = (flag: boolean) => (dispatch: any) => {
    dispatch({ type: SET_PAN_UPLOAD_STATUS, flag });
};

export const resetPan = (flag: boolean) => (dispatch: any) => {
    dispatch({ type: RESET_PAN, flag });
};

export const reselectPan = () => (dispatch: any) => {
    dispatch({ type: RESELECT_PAN });
};

export const updatePanDataField = (panData: string) => (dispatch: any) => {
    dispatch({ type: UPDATE_PAN_DATA, panData });
};

export const setChequeImgSrc = (url: string) => (dispatch: any) => {
    dispatch({ type: SET_CHEQUE_IMG_SRC, url });
};

export const setChequeUploadStatus = (flag: boolean) => (dispatch: any) => {
    dispatch({ type: SET_CHEQUE_UPLOAD_STATUS, flag });
};

export const resetCheque = (flag: boolean) => (dispatch: any) => {
    dispatch({ type: RESET_CHEQUE, flag });
};

export const reselectCheque = () => (dispatch: any) => {
    dispatch({ type: RESELECT_CHEQUE });
};

export const updateChequeDataField = (chequeData: string) => (dispatch: any) => {
    dispatch({ type: UPDATE_CHEQUE_DATA, chequeData });
};

export const setBankStatementImgSrc = (url: string) => (dispatch: any) => {
    dispatch({ type: SET_BANK_STATEMENT_IMG_SRC, url });
};

export const addIBDocuments = (data: any, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: ADD_IB_DOCUMENTS_REQUEST});
    return apiRequest().post(`${config.baseUrl}ib/add_documents`, data)
        .then((res) => {
            dispatch({ type: ADD_IB_DOCUMENTS_SUCCESS, data: data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: ADD_IB_DOCUMENTS_FAILED, error });
            failureCallback();
    });
};

export const getIBDisclosureForms = (successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: GET_IB_DISCLOSURE_FORMS_REQUEST});
    return apiRequest().get(`${config.baseGatewayUrl}ib/sign_forms`)
        .then((res) => {
            dispatch({ type: GET_IB_DISCLOSURE_FORMS_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: GET_IB_DISCLOSURE_FORMS_FAILED, error });
            failureCallback();
        });
};

export const saveSignedDisclosureForms = (data: any, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST});
    return apiRequest().post(`${config.baseGatewayUrl}ib/sign_forms`, data)
        .then((res) => {
            dispatch({ type: SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: SAVE_SIGNED_DISCLOSURE_FORMS_FAILED, error });
            failureCallback();
        });
};

export const saveIBUpdateStatus = (data: any, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: SAVE_IB_UPDATE_STATUS_REQUEST});
    return apiRequest().post(`${config.baseGatewayUrl}ib/update`, data)
        .then((res) => {
            dispatch({ type: SAVE_IB_UPDATE_STATUS_SUCCESS, data: data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: SAVE_IB_UPDATE_STATUS_FAILED, error });
            failureCallback();
        });
};

export const createIbAccount = (data: any, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: CREATE_IB_ACCOUNT_REQUEST});
    return apiRequest().post(`${config.baseGatewayUrl}ib/create_account`, data)
        .then((res) => {
            dispatch({ type: CREATE_IB_ACCOUNT_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: CREATE_IB_ACCOUNT_FAILED, error });
            failureCallback();
    });
};

export const getPanOcrData = (data: string, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: GET_PAN_OCR_DATA_REQUEST });
    return apiRequest().post(`${config.baseGatewayUrl}ocr/pan`, { image64: data })
        .then((res) => {
            dispatch({ type: GET_PAN_OCR_DATA_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: GET_PAN_OCR_DATA_FAILED, error });
            failureCallback();
        });
};

export const verifyPanData = (panData: any, successCallback: any, failureCallback: any, kycNotAvailableCallback: any) => (dispatch: any) => {
    dispatch({ type: VERIFY_PAN_DATA_REQUEST, data: panData});
    return apiRequest().post(`${config.baseUrl}api/pan/details`,  panData )
        .then((res) => {
            if(res.data.sub_code === 200) {
                dispatch({ type: VERIFY_PAN_DATA_SUCCESS, data: res.data });
                successCallback();
            }
            else{
                dispatch({ type: VERIFY_PAN_DATA_STATUS, data: res.data });
                kycNotAvailableCallback();
            }
        })
        .catch((error) => {
            console.log(error,"error")
            dispatch({ type: VERIFY_PAN_DATA_FAILED, error:error.message });
            failureCallback();
        });
};

export const getChequeOcrData = (data: string, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: GET_CHEQUE_OCR_DATA_REQUEST });
    return apiRequest().post(`${config.baseGatewayUrl}ocr/cheque`, { image64: data })
        .then((res) => {
            dispatch({ type: GET_CHEQUE_OCR_DATA_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: GET_CHEQUE_OCR_DATA_FAILED, error });
            failureCallback();
        });
};

export const verifyAccount = (accountNo: string, ifsc: string, micr: string, bankDetailsSource: string, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: VERIFY_ACCOUNT_REQUEST });
    return apiRequest().post(`${config.baseUrl}api/verify_account`, { accountNo, ifsc, micr, bankDetailsSource, user: getFromLocalStorage('userId') })
        .then((res) => {
            dispatch({ type: VERIFY_ACCOUNT_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: VERIFY_ACCOUNT_FAILED, error });
            failureCallback();
        });
};

export const parseBankStatement = (pdfUrl: string, password: string, successCallback: any, validationCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: PARSE_BANK_STATEMENT_REQUEST});
    return apiRequest().post(`${config.baseGatewayUrl}ocr/bankstatement`, { pdf64: pdfUrl, pwd: password })
        .then((res) => {
             if(res.data.sub_status_code === 200) {
                 console.log(res)
                dispatch({ type: PARSE_BANK_STATEMENT_SUCCESS, data: {account: res.data.acc_no, ifsc: res.data.ifsc, micr: res.data.micr, message: res.data.message, sub_status_code: res.data.sub_status_code} });
                successCallback();
             }
             else{
                 dispatch({ type: PARSE_BANK_STATEMENT_VALIDATION, data: res.data });
                 validationCallback();
             }
        })
        .catch((error) => {
            dispatch({ type: PARSE_BANK_STATEMENT_FAILED, error });
            console.log(error,"error")
            failureCallback();
        });
};

export const sendEmailVerification = (email: string, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: SEND_EMAIL_VERIFICATION_REQUEST, data: {email}});
    return apiRequest().post(`${config.baseUrl}api/send_email_verification`, { email })
        .then((res) => {
            dispatch({ type: SEND_EMAIL_VERIFICATION_SUCCESS, data: res.data, email: email });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: SEND_EMAIL_VERIFICATION_FAILED, error });
            failureCallback();
        });
};

export const getIBAccountStatus = (successCallback: any, failureCallback: any,key:string) => (dispatch: any) => {
    dispatch({ type: GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST});

    return apiRequest().get(`${config.baseUrl}api/contract_status?key=${key}`)
        .then((res) => {
            dispatch({ type: GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED, error });
            failureCallback();
        });
};

export const getAccountStatus = (successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: GET_IB_ACCOUNT_STATUS_REQUEST});
    return apiRequest().get(`${config.baseGatewayUrl}ib/get_account_status`)
        .then((res) => {
            dispatch({ type: GET_IB_ACCOUNT_STATUS_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: GET_IB_ACCOUNT_STATUS_FAILED, error });
            failureCallback();
        });
};

export const emailVerification = (verificationCode: string) => (dispatch: any) => {
    dispatch({ type: VERIFY_EMAIL_REQUEST });
    return apiRequest().post(`${config.baseUrl}/api/user_email_verify`, { verificationCode })
        .then((res) => {
          dispatch({ type: VERIFY_EMAIL_SUCCESS, data: res.data });
        })
        .catch((error) => {
          dispatch({ type: VERIFY_EMAIL_FAILED, error });
        });
  };

  export const fetchKYCData = (successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: FETCH_KYC_REQUEST});
    return apiRequest().post(`${config.baseGatewayUrl}cvlkra/fetch/kyc/data`, {})
        .then((res) => {
            dispatch({ type: FETCH_KYC_SUCCESS, data: res.data });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: FETCH_KYC_FAILED, error });
            failureCallback();
        });
};

export const getUserDetails = (
    successCallback: any = noop,
    failureCallback: any = noop
) => (dispatch: any) => {
    dispatch({ type: GET_USER_DETAILS_REQUEST });
    // pushNextMsg();
    return apiRequest()
        .get(`${config.baseUrl}user/details`)
        .then(res => {
            console.log('res.data', res)
            dispatch({ type: GET_USER_DETAILS_SUCCESS, data: res.data });
            successCallback();
        })
        .catch(error => {
            dispatch({ type: GET_USER_DETAILS_FAILED, error });
            failureCallback();
        });
};

export const getTemplate = (template: string,editable: string) => (dispatch: any) => {
    console.log(template,"template");
    dispatch({ type: GET_TEMPLATE_RATE_REQUEST, data:template});
    // pushNextMsg();
    return apiRequest()
    .get(`${config.baseUrl}api/fee_advise?key=${template}`)
        .then(res => {
            window.localStorage.setItem('templateKey',template);
            window.localStorage.setItem('editable',editable);
            dispatch({ type:  GET_TEMPLATE_RATE_SUCCESS, data: res.data,editable:editable });
           
        })
        .catch(error => {
            dispatch({ type: GET_TEMPLATE_RATE_FAILED, error });
          
        });
};