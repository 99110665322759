import { combineReducers } from 'redux';
import appStore from './appReducer';
import riskStore from './riskReducer';
import upiStore from './upiReducer';
import bankDetailsStore from './bankDetailsReducer';
import advisoryStore from './advisoryReducer';
import emailStore from './emailReducer';
import mfOauthStore from './OauthReducer';

export default combineReducers({
  appStore,
  riskStore,
  advisoryStore,
  emailStore,
  upiStore,
  bankDetailsStore,
  mfOauthStore
});
