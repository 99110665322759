import axios from 'axios';
import { get } from 'lodash';
import { decrypt, encrypt } from "../utils/encrypt";
import { getAuthToken } from "../utils/authService";
import { tokenExpiry } from "./app.actions";
import { store } from '../store';
const {dispatch} = store;

axios.interceptors.response.use(function (response) {
    // if(
    //     response.config.url !== 'https://chat-crm.lotusdew.in/chatbot/get' &&
    //     response.config.url !== 'https://chat-crm.lotusdew.in/dev-chatbot/get'
    // ) {
    //     const decryptedResponse = decrypt(get(response.data, 'encryptedData', ''));
    //     response['data'] = JSON.parse(decryptedResponse);
    // }
    const decryptedResponse = decrypt(get(response.data, 'encryptedData', ''));
    response['data'] = JSON.parse(decryptedResponse);
    return response;
}, function (error) {
    if(error.response.status === 401){
         dispatch(tokenExpiry());
    }
    if(error.config.method === 'post') {
    }
    return Promise.reject(error);
});

export const apiRequest = () => {
    const token = getAuthToken();
    const defaultOptions = {
        withCredentials:true,
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        },
    };
    return {
        get: (url, options = {}) => {
            return axios.get(url, { ...defaultOptions, ...options });
        },
        post: (url, data, options = {}, formData = false) => {
            const encryptedData = encrypt(JSON.stringify(data))
            return axios.post(url, formData ? data : { encryptedData }, { ...defaultOptions, ...options });
        },
        put: (url, data, options = {}) => {
            const encryptedData = encrypt(JSON.stringify(data))
            return axios.put(url, encryptedData, { ...defaultOptions, ...options });
        },
        delete: (url, options = {}) => {
            return axios.delete(url, { ...defaultOptions, ...options });
        },
    };
};
