import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './Reducers/rootReducer';
import logger from 'redux-logger'

const dev = process.env.NODE_ENV === 'development';

function configureStore(initialState={}) {
  if (dev) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
      rootReducer,
      initialState,
      composeEnhancers(applyMiddleware(thunk, logger)),
    );
  }
  // production mode store
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk),
  );
}

export const store = configureStore();