import React, { useEffect, Suspense, lazy ,useState} from 'react';


import { Router, Route, Switch,Redirect  ,withRouter  } from 'react-router-dom';
import './App.css';
import './fonts/MyFontsWebfontsKit.css';
import { history } from './History';
import AppLoader from './components/appLoader/appLoader';
import {getTemplate} from './Actions/app.actions'
import { connect } from "react-redux";


const Login = lazy(() =>
  import('./components/Login/Login' /* webpackChunkName: "login" */)
);
const Home = lazy(() =>
  import('./components/Home/Home' /* webpackChunkName: "home" */)
);
const Layout = lazy(() =>
  import('./components/Layout/Layout' /* webpackChunkName: "layout" */)
);
const IBForm = lazy(() =>
  import('./components/riskForm/riskForm' /* webpackChunkName: "ibform" */)
);
const Proofs = lazy(() =>
  import('./components/Proofs/Proofs' /* webpackChunkName: "proofs" */)
);
const Disclosures = lazy(() =>
  import(
    './components/Disclosures/Disclosures' /* webpackChunkName: "disclosures" */
  )
);
const CreateIb = lazy(() =>
  import('./components/CreateIb/CreateIb' /* webpackChunkName: "createIb" */)
);
const Stepper = lazy(() =>
  import('./components/Stepper/Stepper' /* webpackChunkName: "createIb" */)
);
const NoMatchPage=lazy(() =>
import ('./components/NoMatchPage/NoMatchPage' /*)
)
const Landing = lazy(() =>
  import('./components/Landing/Landing' /* webpackChunkName: "createIb" */)
);

const PanOcrData = lazy(() =>
  import(
    './components/kyc/panOcrData/panOcrData' /* webpackChunkName: "panOcrData" */
  )
);
const ChequeOcrData = lazy(() =>
  import(
    './components/bankDetails/chequeOcrData/chequeOcrData' /* webpackChunkName: "chequeOcrData" */
  )
);
const UserVerification = lazy(() =>
  import(
    './Containers/UserVerification' /* webpackChunkName: "user-verification" */
  )
);
const  RouteWithNavBar=({component:Component, ...rest})=>{
    return( <Route {...rest}>
        <Layout />
        <Component />
       </Route>
     );
    }
function App({getTemplate,templateRate,templateExists,templateMsg}) {
  const [startRedirect,setStartRedirect]=useState(false)
  const [template,setTemplate]=useState(null)
  useEffect(() => {
    let endpoint = history.location.pathname;
    let temp = endpoint.replace('/', '');
    setTemplate(temp)
    //if (
    //  template !== 'openAccount' &&
    //  template !== '' &&
    //  template !== 'home' &&
    //  template !== 'user_verification'
    //) {
 
    //  getTemplate(template);
    //}
    //if(template === ''){
    //    getTemplate('default');
    //}
  }, []);
  useEffect(() => {
    if (
        template !== 'openAccount' &&
        template !== '' &&
        template !== 'home' &&
        template !== 'user_verification'
        && template !== null
      ) {
          
        getTemplate(template.endsWith('E') ? template.slice(0,-1) :template,template.endsWith('E') );
      }
      if(template === '' ){
          getTemplate('default',template.endsWith('E'));
      }else if(template === 'openAccount'){
        getTemplate(window.localStorage.getItem("templateKey"),window.localStorage.getItem("editable"));
      }
  },[template])
  useEffect(() => {
    if (templateRate !== 0 && templateExists &&  template !== 'openAccount' &&
    template !== '' &&
    template !== 'home' 
   ) {
      history.push('/');
    }
    if (templateMsg === 'Template not found' || templateMsg === 'failed') {
      setStartRedirect(true);
    }
  }, [templateRate, templateMsg]);
  console.log(
    templateRate === 0 && !templateExists && startRedirect,
    'condition'
  );
  return (
    //  <Router history={history}>
    <div className="App">
      <Suspense fallback={<AppLoader />}>
        {/*<Layout>*/}
        <Switch>
          {/*<Route path='/' exact component={Login} />*/}
          <RouteWithNavBar exact path="/" component={Login} />
          {/*<Route path='/home' component={Home} />*/}
          <RouteWithNavBar exact path="/home" component={Home} />
          {/*<Route path='/openAccount' component={Stepper} />*/}
          <RouteWithNavBar path="/openAccount" component={Stepper} />
          <Route path='/user_verification' component={UserVerification} />
          {/*<Route
        
            path="/"
            render={() =>
              templateRate !== 0 && templateExists ? (
                <Redirect to="/" />
              ) : (
                <NoMatchPage />
              )
            }
          />*/}
          {templateRate === 0 && !templateExists && startRedirect && (
            <Route component={NoMatchPage} />
          )}
          {/*<Route render={() => <Redirect to="/" />} />*/}
          {/*<Route path='/pan' component={PanOcrData} />
                <Route path='/bankdetails' component={ChequeOcrData} />
                <Route path='/create' component={CreateIb} />
                <Route path='/user_verification' component={UserVerification} />*/}
          {/* <Route path="/ib/form" component={IBForm} />
                              <Route path="/ib/proofs" component={Proofs} />
                              <Route path="/ib/disclosures" component={Disclosures} />
                              <Route path="/ib/create" component={CreateIb} /> */}
        </Switch>
        {/*</Layout>*/}
      </Suspense>
    </div>
    //  </Router>
  );
}
const mapStateToProps = (state) => ({
    templateRate: state.appStore.templateRate,
    templateExists: state.appStore.templateExists,
    templateMsg: state.appStore.templateMsg,

});

const mapDispatchToProps = (dispatch) => ({
    getTemplate: (template,editable) => dispatch(getTemplate(template,editable)),
   
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
