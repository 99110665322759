// Action types

export const SEND_PHONE_VERIFICATION_REQUEST =
  'SEND_PHONE_VERIFICATION_REQUEST';
export const SEND_PHONE_VERIFICATION_SUCCESS =
  'SEND_PHONE_VERIFICATION_SUCCESS';
export const SEND_PHONE_VERIFICATION_FAILED = 'SEND_PHONE_VERIFICATION_FAILED';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

export const GET_DOCUMENTS_FLAG_REQUEST = 'GET_DOCUMENTS_FLAG_REQUEST';
export const GET_DOCUMENTS_FLAG_SUCCESS = 'GET_DOCUMENTS_FLAG_SUCCESS';
export const GET_DOCUMENTS_FLAG_FAILED = 'GET_DOCUMENTS_FLAG_FAILED';

export const GET_IB_USER_DETAILS_REQUEST = 'GET_IB_USER_DETAILS_REQUEST';
export const GET_IB_USER_DETAILS_SUCCESS = 'GET_IB_USER_DETAILS_SUCCESS';
export const GET_IB_USER_DETAILS_FAILED = 'GET_IB_USER_DETAILS_FAILED';
export const SAVE_IB_DETAILS_FORM_REQUEST = 'SAVE_IB_DETAILS_FORM_REQUEST';
export const SAVE_IB_DETAILS_FORM_SUCCESS = 'SAVE_IB_DETAILS_FORM_SUCCESS';
export const SAVE_IB_DETAILS_FORM_FAILED = 'SAVE_IB_DETAILS_FORM_FAILED';

export const ADD_IB_DOCUMENTS_REQUEST = 'ADD_IB_DOCUMENTS_REQUEST';
export const ADD_IB_DOCUMENTS_SUCCESS = 'ADD_IB_DOCUMENTS_SUCCESS';
export const ADD_IB_DOCUMENTS_FAILED = 'ADD_IB_DOCUMENTS_FAILED';
export const GET_IB_DISCLOSURE_FORMS_REQUEST =
  'GET_IB_DISCLOSURE_FORMS_REQUEST';
export const GET_IB_DISCLOSURE_FORMS_SUCCESS =
  'GET_IB_DISCLOSURE_FORMS_SUCCESS';
export const GET_IB_DISCLOSURE_FORMS_FAILED = 'GET_IB_DISCLOSURE_FORMS_FAILED';

export const SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST =
  'SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST';
export const SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS =
  'SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS';
export const SAVE_SIGNED_DISCLOSURE_FORMS_FAILED =
  'SAVE_SIGNED_DISCLOSURE_FORMS_FAILED';

export const SAVE_IB_UPDATE_STATUS_REQUEST = 'SAVE_IB_UPDATE_STATUS_REQUEST';
export const SAVE_IB_UPDATE_STATUS_SUCCESS = 'SAVE_IB_UPDATE_STATUS_SUCCESS';
export const SAVE_IB_UPDATE_STATUS_FAILED = 'SAVE_IB_UPDATE_STATUS_FAILED';
export const CREATE_IB_ACCOUNT_REQUEST = 'CREATE_IB_ACCOUNT_REQUEST';
export const CREATE_IB_ACCOUNT_SUCCESS = 'CREATE_IB_ACCOUNT_SUCCESS';
export const CREATE_IB_ACCOUNT_FAILED = 'CREATE_IB_ACCOUNT_FAILED';

export const GET_STATE_AND_CITY_REQUEST = 'GET_STATE_AND_CITY_REQUEST';
export const GET_STATE_AND_CITY_SUCCESS = 'GET_STATE_AND_CITY_SUCCESS';
export const GET_STATE_AND_CITY_FAILED = 'GET_STATE_AND_CITY_FAILED';

export const SET_ACTIVE_STEP_NUMBER = 'SET_ACTIVE_STEP_NUMBER';
export const SET_PAN_IMG_SRC = 'SET_PAN_IMG_SRC';
export const SET_PAN_UPLOAD_STATUS = 'SET_PAN_UPLOAD_STATUS';
export const RESET_PAN = 'RESET_PAN';
export const RESELECT_PAN = 'RESELECT_PAN';
export const UPDATE_PAN_DATA = 'UPDATE_PAN_DATA';
export const SET_CHEQUE_IMG_SRC = 'SET_CHEQUE_IMG_SRC';
export const SET_CHEQUE_UPLOAD_STATUS = 'SET_CHEQUE_UPLOAD_STATUS';
export const RESET_CHEQUE = 'RESET_CHEQUE';
export const RESELECT_CHEQUE = 'RESELECT_CHEQUE';
export const UPDATE_CHEQUE_DATA = 'UPDATE_CHEQUE_DATA';
export const SET_BANK_STATEMENT_IMG_SRC = 'SET_BANK_STATEMENT_IMG_SRC';
export const SET_COMPLETED_STEP_NUMBER = 'SET_COMPLETED_STEP_NUMBER';
export const TOKEN_EXPIRY = 'TOKEN_EXPIRY';

export const GET_PAN_OCR_DATA_REQUEST = 'GET_PAN_OCR_DATA_REQUEST';
export const GET_PAN_OCR_DATA_SUCCESS = 'GET_PAN_OCR_DATA_SUCCESS';
export const GET_PAN_OCR_DATA_FAILED = 'GET_PAN_OCR_DATA_FAILED';

export const GET_CHEQUE_OCR_DATA_REQUEST = 'GET_CHEQUE_OCR_DATA_REQUEST';
export const GET_CHEQUE_OCR_DATA_SUCCESS = 'GET_CHEQUE_OCR_DATA_SUCCESS';
export const GET_CHEQUE_OCR_DATA_FAILED = 'GET_CHEQUE_OCR_DATA_FAILED';

export const VERIFY_ACCOUNT_REQUEST = 'VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAILED = 'VERIFY_ACCOUNT_FAILED';

export const VERIFY_PAN_DATA_REQUEST = 'VERIFY_PAN_DATA_REQUEST';
export const VERIFY_PAN_DATA_SUCCESS = 'VERIFY_PAN_DATA_SUCCESS';
export const VERIFY_PAN_DATA_FAILED = 'VERIFY_PAN_DATA_FAILED';
export const VERIFY_PAN_DATA_STATUS = 'VERIFY_PAN_DATA_STATUS';

export const PARSE_BANK_STATEMENT_REQUEST = 'PARSE_BANK_STATEMENT_REQUEST';
export const PARSE_BANK_STATEMENT_VALIDATION =
  'PARSE_BANK_STATEMENT_VALIDATION';
export const PARSE_BANK_STATEMENT_SUCCESS = 'PARSE_BANK_STATEMENT_SUCCESS';
export const PARSE_BANK_STATEMENT_FAILED = 'PARSE_BANK_STATEMENT_FAILED';

export const SEND_EMAIL_VERIFICATION_REQUEST =
  'SEND_EMAIL_VERIFICATION_REQUEST';
export const SEND_EMAIL_VERIFICATION_SUCCESS =
  'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_FAILED = 'SEND_EMAIL_VERIFICATION_FAILED';

export const GET_IB_ACCOUNT_STATUS_REQUEST = 'GET_IB_ACCOUNT_STATUS_REQUEST';
export const GET_IB_ACCOUNT_STATUS_SUCCESS = 'GET_IB_ACCOUNT_STATUS_SUCCESS';
export const GET_IB_ACCOUNT_STATUS_FAILED = 'GET_IB_ACCOUNT_STATUS_FAILED';

export const GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST =
  'GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST';
export const GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS =
  'GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS';
export const GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED =
  'GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED';

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED';

export const FETCH_KYC_REQUEST = 'FETCH_KYC_REQUEST';
export const FETCH_KYC_SUCCESS = 'FETCH_KYC_SUCCESS';
export const FETCH_KYC_FAILED = 'FETCH_KYC_FAILED';

export const GET_QUESTIONS_LIST_REQUEST = 'GET_QUESTIONS_LIST_REQUEST';
export const GET_QUESTIONS_LIST_SUCCESS = 'GET_QUESTIONS_LIST_SUCCESS';
export const GET_QUESTIONS_LIST_FAILED = 'GET_QUESTIONS_LIST_FAILED';

export const SAVE_ANSWERS_REQUEST = 'SAVE_ANSWERS_REQUEST';
export const SAVE_ANSWERS_SUCCESS = 'SAVE_ANSWERS_SUCCESS';
export const SAVE_ANSWERS_FAILED = 'SAVE_ANSWERS_FAILED';

export const UPDATE_RISK_SCORE_REQUEST = 'UPDATE_RISK_SCORE_REQUEST';
export const UPDATE_RISK_SCORE_SUCCESS = 'UPDATE_RISK_SCORE_SUCCESS';
export const UPDATE_RISK_SCORE_FAILED = 'UPDATE_RISK_SCORE_FAILED';

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const GET_ADVISORY_CONTRACT_REQUEST = 'GET_ADVISORY_CONTRACT_REQUEST';
export const GET_ADVISORY_CONTRACT_SUCCESS = 'GET_ADVISORY_CONTRACT_SUCCESS';
export const GET_ADVISORY_CONTRACT_FAILED = 'GET_ADVISORY_CONTRACT_FAILED';
export const FETCH_ADVISORY_SIGNED_PDF_REQUEST =
  'FETCH_ADVISORY_SIGNED_PDF_REQUEST';
export const FETCH_ADVISORY_SIGNED_PDF_SUCCESS =
  'FETCH_ADVISORY_SIGNED_PDF_SUCCESS';
export const FETCH_ADVISORY_SIGNED_PDF_FAILED =
  'FETCH_ADVISORY_SIGNED_PDF_FAILED';

export const CREATE_MANDATE_REQUEST = 'CREATE_MANDATE_REQUEST';
export const CREATE_MANDATE_SUCCESS = 'CREATE_MANDATE_SUCCESS';
export const CREATE_MANDATE_FAILED = 'CREATE_MANDATE_FAILED';

export const EXECUTE_MANDATE_REQUEST = 'EXECUTE_MANDATE_REQUEST';
export const EXECUTE_MANDATE_SUCCESS = 'EXECUTE_MANDATE_SUCCESS';
export const EXECUTE_MANDATE_FAILED = 'EXECUTE_MANDATE_FAILED';

export const APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAILED = 'APPLY_COUPON_FAILED';

export const SET_ESIGN_DOC_TYPE = 'SET_ESIGN_DOC_TYPE';

export const GET_ESIGN_XML_REQUEST = 'GET_ESIGN_XML_REQUEST';
export const GET_ESIGN_XML_SUCCESS = 'GET_ESIGN_XML_SUCCESS';
export const GET_ESIGN_XML_FAILED = 'GET_ESIGN_XML_FAILED';

export const FETCH_ESIGN_DOCUMENT_REQUEST = 'FETCH_ESIGN_DOCUMENT_REQUEST';
export const FETCH_ESIGN_DOCUMENT_SUCCESS = 'FETCH_ESIGN_DOCUMENT_SUCCESS';
export const FETCH_ESIGN_DOCUMENT_FAILED = 'FETCH_ESIGN_DOCUMENT_FAILED';

export const CHANGE_SELECTION_VALUE = 'CHANGE_SELECTION_VALUE';

export const UPLOAD_SIGNED_PDF_REQUEST = ' UPLOAD_SIGNED_PDF_REQUEST';
export const UPLOAD_SIGNED_PDF_SUCCESS = 'UPLOAD_SIGNED_PDF_SUCCESS';
export const UPLOAD_SIGNED_PDF_FAILED = 'UPLOAD_SIGNED_PDF_FAILED';

export const SUBMIT_RISK_FORMS_REQUEST = ' SUBMIT_RISK_FORMS_REQUEST';
export const SUBMIT_RISK_FORMS_SUCCESS = 'SUBMIT_RISK_FORMS_SUCCESS';
export const SUBMIT_RISK_FORMS_FAILURE = 'SUBMIT_RISK_FORMS_FAILURE';

export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
export const UPDATE_EMAIL_VERFICATION_STATE = 'UPDATE_EMAIL_VERIFICATION_STATE'
export const UPDATE_BANK_DETAILS_STATE = 'UPDATE_BANK_DETAILS_STATE'

export const GET_TEMPLATE_RATE_REQUEST = 'GET_TEMPLATE_RATE_REQUEST';
export const GET_TEMPLATE_RATE_SUCCESS = 'GET_TEMPLATE_RATE_SUCCESS';
export const GET_TEMPLATE_RATE_FAILED = 'GET_TEMPLATE_RATE_FAILED';

export const UPDATE_TEMPLATE_KEY_REQUEST = 'UPDATE_TEMPLATE_KEY_REQUEST';
export const UPDATE_TEMPLATE_KEY_SUCCESS = 'UPDATE_TEMPLATE_KEY_SUCCESS';
export const UPDATE_TEMPLATE_KEY_FAILED = 'UPDATE_TEMPLATE_KEY_FAILED';

export const FETCH_KYC_EMAIL_REQUEST = 'FETCH_KYC_EMAIL_REQUEST';
export const FETCH_KYC_EMAIL_SUCCESS = 'FETCH_KYC_EMAIL_SUCCESS';
export const UNSUPPORTED_AUTO_EMAIL = 'UNSUPPORTED_AUTO_EMAIL';
export const FETCH_KYC_EMAIL_FAILED = 'FETCH_KYC_EMAIL_FAILED';

export const SEND_OAUTH_EMAIL_REQUEST = 'SEND_OAUTH_EMAIL_REQUEST';
export const SEND_OAUTH_EMAIL_SUCCESS = 'SEND_OAUTH_EMAIL_SUCCESS';
export const SEND_OAUTH_EMAIL_FAILED = 'SEND_OAUTH_EMAIL_FAILED';