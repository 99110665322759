import {
    UPDATE_EMAIL_VERFICATION_STATE
} from '../Constants/actionTypes.constants';

export const initialStore = {
    completed: false,
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case UPDATE_EMAIL_VERFICATION_STATE:
            return {
                ...state,
                completed: true,
            };
        default:
            return state
    }
}
